import React, { useRef, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';
import SvgInParentPaddingsAnimated from './svgInParentPaddings/SvgInParentPaddingsAnimated';
import { THEME_COLOR_NAMES } from "../../theme/constants"

const animationConfig1 = [
    {
        path: 'top',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsHorizontal(3, refParentWidth, refParentPadding.bottom).a,
                // func: morphingFuncMap.horizontal,
                func: "horizontal",
                waves: 20,
                key: 'hFromDecreasingToIncreasing',
                duration: 2
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontal",
                waves: 20,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontal",
                waves: 3,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'morphing',
                func: "horizontalSpring",
                waves: 3,
                key: 'a',
                isSpring: true,
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontalIncreasingDecreasing",
                waves: 3,
                duration: 2
            },
        ],
    },
    {
        path: 'right',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsVertical(13, refParentHeight, refParentPadding.right).b,
                func: "vertical",
                waves: 13,
                key: 'vFromDecreasingToIncreasing',
                duration: 2
            },
        ],
    },
    {
        path: 'bottom',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsHorizontal(3, refParentWidth, refParentPadding.bottom).b,
                func: "horizontal",
                waves: 3,
                key: 'hFromIncreasingToDecreasingIncreasing',
                duration: 2
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontal",
                waves: 3,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontal",
                waves: 13,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'morphing',
                func: "horizontalSpring",
                waves: 13,
                key: 'hFromIncreasingToDecreasing',
                isSpring: true,
            },
            // {
            //     type: 'wave',
            //     // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
            //     func: "HorizontalIncreasingDecreasing",
            //     waves: 13,
            //     duration: 2
            // },
        ],
    },
    {
        path: 'left',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsVertical(13, refParentHeight, refParentPadding.left).a,
                func: "vertical",
                waves: 13,
                key: 'a',
                duration: 2
            },
        ],
    },
]
const animationConfig2 = [
    {
        path: 'top',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsHorizontal(3, refParentWidth, refParentPadding.bottom).a,
                // func: morphingFuncMap.horizontal,
                func: "horizontal",
                waves: 3,
                key: 'hFromDecreasingToIncreasing',
                duration: 2
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontal",
                waves: 3,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontal",
                waves: 10,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'morphing',
                func: "horizontalSpring",
                waves: 10,
                key: 'a',
                isSpring: true,
            },
            {
                type: 'wave',
                // values: createHorizontalWavesPathWithIncreasingDecreasingAmplitude(13, refParentWidth, refParentHeight),
                // func: createWavesFuncMap.HorizontalIncreasingDecreasing,
                func: "horizontalIncreasingDecreasing",
                waves: 10,
                duration: 2
            },
        ],
    },
    {
        path: 'right',
        animations: [
            {
                type: 'morphing',
                // values: morphingPathsVertical(13, refParentHeight, refParentPadding.right).b,
                func: "vertical",
                waves: 13,
                key: 'vFromDecreasingToIncreasing',
                duration: 2
            },
            {
                type: 'morphing',
                func: "verticalSpring",
                waves: 13,
                key: 'vFromIncreasingToDecreasing',
                isSpring: true,
            },
        ],
    },
    {
        path: 'bottom',
        animations: [
            {
                type: 'morphing',
                func: "horizontal",
                waves: 3,
                key: 'hFromIncreasingToDecreasingIncreasing',
                duration: 2
            },
            {
                type: 'wave',
                func: "horizontal",
                waves: 3,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'wave',
                func: "horizontal",
                waves: 13,
                argAfter: 0,
                duration: 2
            },
            {
                type: 'morphing',
                func: "horizontalSpring",
                waves: 13,
                key: 'hFromIncreasingToDecreasing',
                isSpring: true,
            },
        ],
    },
    {
        path: 'left',
        animations: [
            {
                type: 'morphing',
                func: "vertical",
                waves: 13,
                key: 'a',
                duration: 2
            },
            {
                type: 'morphing',
                func: "verticalSpring",
                waves: 13,
                key: 'vFromDecreasingToIncreasingDecreasing',
                isSpring: true,
            },
            {
                type: 'morphing',
                func: "verticalSpring",
                waves: 13,
                key: 'vFromDecreasingToIncreasingDecreasingH20',
                isSpring: true,
            },
            {
                type: 'morphing',
                func: "verticalSpring",
                waves: 13,
                key: 'vFromDecreasingToIncreasingDecreasingW20',
                isSpring: true,
            },

        ],
    },
]

const arrAnim = [ animationConfig1, animationConfig2 ]


const DivsWithSvgInParentPaddingsViaMuiImageList = () => {
    const theme = useTheme();
    const bgImageFromGradientColor1 = theme.palette.info.main
    const bgImageFromGradientColor2 = theme.palette.secondary.main
    const bgImageToGradientColor1 = theme.palette.secondary2.main
    const bgImageToGradientColor2 = theme.palette.warning.main
    const length = 12;


    // Use media query to adjust columns based on screen width
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const matchesMD = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

    let cols;
    const rowHeight = 70;
    if (matchesXS) {
        cols = 3;
    } else if (matchesSM) {
        cols = 4;
    } else if (matchesMD) {
        cols = 5;
    } else if (matchesLG) {
        cols = 7;
    }
    // Generate an array of random padding values
    // const paddings = Array.from({ length: 20 }, () => Math.floor(Math.random() * 81 + 20));
    // Create an array of refs
    // const paddings = Array.from({ length: 19 }, (_, i) => 2 * i + 4);
    // const refs = Array.from({ length: 19 }, () => useRef(null));
    const paddings = Array.from({ length: 19 }, (_, i) => 2 * i + 4);
    const refs = Array.from({ length: 19 }, () => useRef(null));

    const items = useMemo(() => {
        return paddings.map((padding, index) => {
            const iAnim = index % arrAnim.length
            const rows = index % 2 + 1
            const cols = index % 2 + 1
            const iColor = index % THEME_COLOR_NAMES.length
            const options = { strokeColor: THEME_COLOR_NAMES[ iColor ], fillColor: THEME_COLOR_NAMES[ iColor ] }
            return (
                <ImageListItem key={`ImageListItem-${index}`}
                    cols={cols || 1}
                    rows={rows || 1}
                    sx={{
                    }}
                >
                    <Box
                        className="outer-box clay"
                        ref={refs[ index ]}
                        sx={{
                            // boxShadow: 15,
                            p: `${padding}px`,
                            bgcolor: 'secondary.main',
                            color: 'secondary.contrastText',
                            border: '1px solid',
                            borderColor: 'divider',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            position: 'relative',
                            // height: "160px",
                            // width: "160px",
                            height: "100%",
                        }}
                    >
                        <SvgInParentPaddingsAnimated refParent={refs[ index ]} animationConfig={arrAnim[ iAnim ]} options={options} />

                        <Box sx={{
                            width: "100%", height: "100%",
                            // bgcolor: "warning.main", 
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 'thin',
                            borderStyle: 'solid',
                            borderColor: 'divider',
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                        }}
                            className="inner-box clay"
                        >
                            {padding}%
                        </Box>
                        {/* <ImageListItemBar
                    title={<Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                    />}
                    subtitle={<Skeleton animation="wave" height={10} width="40%" />}
                    position="below"
                    sx={{
                        bgcolor: 'secondary2.main',
                        color: 'secondary2.contrastText',
                        // border: '1px solid',
                        // borderColor: 'divider',

                    }}
                /> */}
                    </Box>
                </ImageListItem>
            )
        })
    }, [ arrAnim, paddings, refs, THEME_COLOR_NAMES ])

    return (
        <ImageList
            sx={{
                // width: 600,
                // width: 600,
                // maxHeight: "300px",
                width: "100%",
                // overflowY: 'scroll'
            }}
            cols={cols}
            rowHeight={rowHeight} //{164}
            // variant="quilted"
            variant="masonry"
            gap={8}
        >
            {items}
        </ImageList >
    );
};

export default DivsWithSvgInParentPaddingsViaMuiImageList;